/*eslint-disable*/

import { ucfirst } from '@/utils/helpers';
import MapLayer from '@/interfaces/mapLayers/MapLayer';

// const getStepOneCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection;
// const getStepTwoCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
//   && tasq?.responseData?.user_response?.selection_details?.reason;
// const getStepThreeCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
//   && tasq?.responseData?.user_response?.selection_details?.reason
//   && tasq?.responseData?.user_response?.selection_details?.action;
// const getStepFourCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
//   && tasq?.responseData?.user_response?.selection_details?.reason
//   && tasq?.responseData?.user_response?.selection_details?.action
//   && tasq?.responseData?.user_response?.corrected_state_change_date;

// const getStepLabel = (tasq: TasqJob) => {
//   let label = 'No action';
//   if (tasq.stepOneCompleted) {
//     label = 'Validated';
//   }
//   if (tasq.stepTwoCompleted) {
//     label = 'Labeled';
//   }
//   if (tasq.stepThreeCompleted) {
//     label = 'Actioned';
//   }
//   if (tasq.stepFourCompleted) {
//     label = 'Resolved';
//   }
//   return label;
// };

// const getBgColorClass = (tasq: TasqJobResponse) => {
//   // if (tasq.isWorkflowGen) return 'bg-color--pink';
//   switch (tasq.PredictionType) {
//     case TASQ_PREDICTION_TYPE:
//       return 'bg-tasqGreen';
//     case TASQ_FAILURE_TYPE:
//       return 'bg-tasqRed';
//     case TASQ_DOWN_TYPE:
//     case TASQ_OFF_TARGET_TYPE:
//       return 'bg-tasqOrange';
//     case TASQ_LABEL_TYPE:
//       return 'bg-tasqBlueLight';
//     case TASQ_PRODUCING_TYPE:
//     case TASQ_PM_TYPE:
//     case TASQ_GAUGE_TYPE:
//     case TASQ_REGULATORY_TYPE:
//     case TASQ_SITE_CHECK_TYPE:
//     case TASQ_NO_COMMS_TYPE:
//       return 'bg-tasqGrayDark';
//     case TASQ_SETPOINT_TYPE:
//       return 'bg-tasqBlue';
//     default:
//       return 'bg-tasqYellowDark';
//   }
// };

// const getOverriddenTasqName = (tasq: TasqJobResponse) => {
//   switch (tasq.PredictionType) {
//     case TASQ_PROLONGED_ANOMALY_TYPE:
//     case TASQ_STATE_CHANGE_TYPE:
//       return 'Anomaly';
//     default:
//       return ucfirst(tasq.PredictionType || '');
//   }
// };

const mapMapLayers = (mapRecord, userEmail = ''): MapLayer => {
  // const workflowDetails = t.WorkflowCondition
  //   ? mapConditionDetails(Object.values(JSON.parse(t.WorkflowCondition))) : null;





  const mapLayer = {
    id: mapRecord.MapLayerID,
    assetType: mapRecord.AssetType,
	assetTypeReadable: mapRecord.AssetTypeReadable,
	selectedAssetNameOption: mapRecord.SelectedAssetNameOption,
	enabled: mapRecord.Enabled,
    bucket: mapRecord.Bucket,
    geoJsonURL: mapRecord.GeoJsonURL,
	geoJson: mapRecord.GeoJson,
    key: mapRecord.Key,
    operator: mapRecord.Operator,
    shapefileName: mapRecord.ShapefileName,
    shapefileZipURL: mapRecord.ShapefileZipURL,
    time: mapRecord.time,
    username: mapRecord.Username.toLowerCase(),
	layerName: mapRecord.LayerName,
  };

  return mapLayer;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapMapLayers,
};
